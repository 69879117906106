import { LIMIT_TYPES } from '~/settings'
import { cloneDeep } from 'lodash'
export const normalizeProductDetails = res => {
  if (!res.variants) return new Error('Variants is empty')

  Object
    .values(res.variants)
    .forEach(variant => {
      // add _moq into variant data
      if (variant.limit_type === LIMIT_TYPES.MOQ) {
        const moq = res.products_region_moqs.find(i => i.region_id === variant.region_id)
        variant._moq = moq ? moq.moq : 1
      }
      // make sure promotions is array
      variant._promotions = variant.promotions || []
    })
  return res
}

export const normalizeInactiveSku = (skus = {}) => {
  const newSkus = cloneDeep(skus)

  Object
    .values(newSkus)
    .forEach(sku => {
      sku.__INACTIVE = true
    })

  return newSkus
}

export const mapSyncers = (configs = {}) => {
  return Object.entries(configs).reduce((res, [key, prop]) => {
    res[key] = {
      get () {
        return this[prop]
      },
      set (v) {
        if (prop === 'value') this.$emit('input', v)
        else this.$emit(`update:${prop}`, v)
      }
    }
    return res
  }, {})
}
