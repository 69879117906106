export const BACKWARD_COMPATIBLE_MAP = {
  // common
  '/signin': 'login',
  '/signup': 'register',
  '/auth/reset': 'reset-password',
  // Reset password from email
  '/vendor_auth/new': 'setup-password',
  '/buyer_auth/new': 'setup-password',
  '/logistics_partner_auth/new': 'setup-password',

  // buyer
  '/ordered_variants': 'order-guide',
  '/recommended_products': 'recommended-products',
  '/buyer_dashboard': 'buyers',
  '/buyer_dashboard/analytics': 'buyers-analytics',
  '/buyer_dashboard/orders': 'buyers-orders',
  '/buyer_dashboard/pre_order/orders': 'buyers-orders-pre-order',
  '/buyer_dashboard/samples': 'buyers-samples',
  '/buyer_dashboard/credit_memos': 'buyers-payments-credit-memos',
  '/buyer_dashboard/finance_statements': 'buyers-payments-statements',
  '/buyer_dashboard/settings': 'buyers-settings',
  '/buyer_dashboard/account': 'buyers-settings-general',
  '/buyer_dashboard/payments': 'buyers-settings-payment',
  '/buyer_dashboard/invite_colleagues': 'buyers-settings-invite-colleagues',
  '/buyer_dashboard/email_notifications': 'buyers-settings-notifications',

  // vendor
  '/vendor_dashboard': 'vendors',
  '/vendor_dashboard/analytics': 'vendors-analytics',
  '/vendor_dashboard/orders': 'vendors-orders',
  '/vendor_dashboard/samples': 'vendors-samples',
  '/vendor_dashboard/products': 'vendors-products',
  '/vendor_dashboard/products/new': 'vendors-products-create',
  '/vendor_dashboard/inventory': 'vendors-inventory',
  '/vendor_dashboard/inventory/status': 'vendors-inventory-status',
  '/vendor_dashboard/inventory/inbound_inventory': 'vendors-inventory-inbound',
  '/vendor_dashboard/inventory/inbound_inventory/list': 'vendors-inventory-inbound',
  '/vendor_dashboard/inventory/inbound_inventory/new': 'vendors-inventory-inbound-create',
  '/vendor_dashboard/inventory/withdraw_inventory': 'vendors-inventory-withdrawal-requests',
  '/vendor_dashboard/inventory/withdraw_inventory/list': 'vendors-inventory-withdrawal-requests',
  '/vendor_dashboard/inventory/withdraw_inventory/new': 'vendors-inventory-withdrawal-requests-create',
  '/vendor_dashboard/brands': 'vendors-brands',
  '/vendor_dashboard/brands/new': 'vendors-brands',
  '/vendor_dashboard/payments/payouts': 'vendors-payments-payouts',
  '/vendor_dashboard/payments/commission': 'vendors-payments-commission',
  '/vendor_dashboard/payments/finance-statements': 'vendors-payments-statements',
  '/vendor/vendor_statements': 'vendors-payments-statements',
  '/vendor_dashboard/settings': 'vendors-settings',
  '/vendor_dashboard/account': 'vendors-settings-general',
  '/vendor_dashboard/account/company': 'vendors-settings-general',
  '/vendor_dashboard/account/documents': 'vendors-settings-general',
  '/vendor_dashboard/account/password': 'vendors-settings-general',
  '/vendor_dashboard/setting_payments': 'vendors-settings-payments',
  '/vendor_dashboard/minimums': 'vendors-settings-minimums',
  '/vendor_dashboard/invite_colleagues': 'vendors-settings-invite-colleagues',
  '/vendor_dashboard/email_notifications': 'vendors-settings-notifications',

  // logistic partner
  '/logistics_partner_dashboard': 'lp',
  '/logistics_partner_dashboard/settings': 'lp-settings',
  '/logistics_partner_dashboard/account': 'lp-settings-general',
  '/logistics_partner_dashboard/account/password': 'lp-settings-general',
  '/logistics_partner_dashboard/account/documents': 'lp-settings-general',
  '/logistics_partner_dashboard/orders': 'lp-orders',
  '/logistics_partner_dashboard/inventory': 'lp-inventory-all',
  '/logistics_partner_dashboard/inventory/new': 'lp-inventory-all-new',
  '/logistics_partner_dashboard/inventory/inbound_inventory/list': 'lp-inventory-inbound',
  '/logistics_partner_dashboard/inventory/inbound_inventory': 'lp-inventory-inbound',
  '/logistics_partner_dashboard/inventory/withdraw_inventory/list': 'lp-inventory-withdrawal-requests',
  '/logistics_partner_dashboard/inventory/withdraw_inventory': 'lp-inventory-withdrawal-requests'
}

export default function ({ route, redirect }) {
  if (route.matched.length) return
  let name = BACKWARD_COMPATIBLE_MAP[route.path]
  let additionalParams = {}

  // For VendorDB product variant detail (create + edit) & Request Product Change
  if (route.path?.includes('/vendor_dashboard/product_variants/') ||
    /vendor_dashboard\/products\/(\d+)\/new/.test(route.path) ||
    /vendor_dashboard\/products\/(\d+)\/request_information_change/.test(route.path)
  ) {
    name = 'vendors-products'
  } else if (/\/(\d+)/.test(route.path)) {
    // Support all routes to detail pages with dynamic /id
    const splitRoutePath = route.path?.split(/\/(\d+)/)
    const pathWithoutId = splitRoutePath[0]
    const detailPageId = splitRoutePath[1]

    name = `${BACKWARD_COMPATIBLE_MAP[pathWithoutId]}-id`
    additionalParams = { id: detailPageId }
  }

  if (!name) return
  return redirect({
    name,
    params: {
      ...route.params,
      ...additionalParams
    },
    hash: route.hash,
    query: route.query
  })
}
